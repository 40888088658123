import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				PREMIER LEGAL SOLUTIONS
			</title>
			<meta name={"description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:title"} content={"PREMIER LEGAL SOLUTIONS"} />
			<meta property={"og:description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Components.Hero background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z) 0% 0% /cover repeat scroll padding-box">
			<Override slot="text">
				Premier Hukuk Çözümleri'ni Keşfedin, Güvenilir Hukuk Ortağınız.
			</Override>
			<Override slot="text1" font="--headline2">
				İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin.
			</Override>
			<Override slot="text2">
				Ticaret Hukuku
			</Override>
			<Override slot="text3">
				Aile Hukuku
			</Override>
			<Override slot="text4">
				Ceza Savunması
			</Override>
			<Override slot="text5">
				Yaralanma
			</Override>
			<Override slot="text6" />
			<Override slot="link">
				HİZMETLER
			</Override>
			<Override slot="icon4" />
			<Override slot="box" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Hakkımızda
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Premier Hukuk Çözümleri olarak, hukukun derinlemesine anlaşılmasından ve müşteri odaklı yaklaşımdan gurur duyuyoruz.
					<br />
					<br />
					Deneyimli avukatlarımız çeşitli hukuk alanlarında uzmanlaşmıştır, bu da bize ihtiyaçlarınıza özel kapsamlı hukuki çözümler sunma imkanı tanır.
					<br />
					<br />
					Müşterilerimiz için en iyi sonuçları elde etmeye, dürüstlük, çıkar koruma ve anlayışa odaklanarak çalışıyoruz.
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-darkL2"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Hizmetler
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Kişiselleştirilmiş Hizmet
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							İhtiyaçlarınızı dinliyoruz ve hizmetlerimizi bu doğrultuda uyarlıyoruz.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Deneyimli Profesyoneller
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ekibimiz, çeşitli hukuki alanlarda kanıtlanmış bir başarı geçmişine sahiptir.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Etkili Çözümler
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Zamanınızı ve stresinizi azaltacak etkili ve verimli hukuki çözümler sunuyoruz.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Cevaplarınızı Burada Bulun
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Hukuk firmanız hangi hizmetleri sunuyor?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Premier Hukuk Çözümleri, danışmanlık, mahkemede temsil, sözleşme hazırlama ve analizi, şirketler hukuku, aile hukuku, iş hukuku ve fikri mülkiyet haklarının korunması gibi geniş bir yelpazede hukuki hizmetler sunmaktadır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Online danışmanlık alabilir miyim?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Evet, video bağlantısı, telefon veya e-posta yoluyla online danışmanlık hizmeti sunuyoruz. Bunun için web sitemiz üzerinden veya ofisimizi arayarak önceden randevu almanız gerekmektedir.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Hizmetlerinizin maliyeti nedir?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Hizmetlerimizin maliyeti, hizmet türüne ve davanın karmaşıklığına bağlıdır. Detaylı bilgi almak için lütfen bizimle iletişime geçin, ilk danışmanlığımızın ardından size yaklaşık bir maliyet sunabiliriz.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Fiziksel kişilerle mi çalışıyorsunuz yoksa sadece şirketlerle mi?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Premier Hukuk Çözümleri, hem bireysel müvekkillerle hem de şirketlerle çalışmakta olup, çeşitli hukuk alanlarında hukuki hizmetler sunmaktadır.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Mahkemede beni temsil edebilir misiniz?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Evet, avukatlarımız farklı mahkemelerde müvekkillerimizi temsil etme deneyimine sahiptir.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Davanın görülmesi ne kadar sürer?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Davanın görülme süresi, davanın karmaşıklığına ve mahkeme sürecine bağlıdır. Müvekkillerimizin davalarını mümkün olan en kısa sürede ve etkili bir şekilde çözmeye çalışıyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="text3" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text5" />
			<Override slot="text4" />
			<Override slot="box3" />
			<Override slot="icon2" />
			<Override slot="icon1" />
			<Override slot="icon" />
			<Override slot="text1" />
			<Override slot="button" />
			<Override slot="button1" />
			<Override slot="box" />
		</Components.Contacts>
		<Components.Newfooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});